/* eslint-disable prettier/prettier */
import { Endpoints } from '@shared/constants';
import { useHandleManualInput, useStores } from '@shared/hooks';
import type { Car } from '@shared/types';
import { fetchData } from '@shared/utils';
import { useEffect } from 'react';
import { useQuery, useQueryErrorResetBoundary } from 'react-query';

//TODO: Перенести всю логику в адаптеры

export const useHandleCarNumber = (carNumber?: string) => {
  const {
    MainStore: {
      initProductStore: {
        initState: { code, minProductLimit },
      },
      applicationStore: {
        wantFindCar,
        setWantFindCar,
        setIsManualCarInput,
        setIsCarNumberSmartComponentShowing,
        updateFormValue,
        setHasUnknownCarNumberError,
        incrementCarSearchAttemptsCount,
      },
      productStore: {
        formState: { Car },
        setCar,
      },
    },
  } = useStores();

  const enabled = Boolean(code && carNumber && wantFindCar);
  const carNumberWithoutMask = carNumber?.replaceAll(/[_\s]/g, '');

  const { refetch, data, error } = useQuery(
    ['get-car', carNumber],
    fetchData<Car>({
      //TODO: Вынести replaceAll
      url: `${Endpoints.GET_CAR}?regNum=${carNumberWithoutMask}&productVersion=${code}`,
      method: 'GET',
    }),
    { enabled }
  );

  useEffect(() => {
    if (!wantFindCar) return;

    if (!data) return;

    incrementCarSearchAttemptsCount();

    const isFoundAllCarData = Boolean(
      data?.makeId && data?.modelId && data?.year && data?.power
    );

    if (!isFoundAllCarData) setIsManualCarInput(true);

    setCar(data);

    updateFormValue('Car', {
      ...Car,
      carNumber,
      vin: data.vin,
      marketPrice:
        data?.price?.price && isFoundAllCarData
          ? data?.price?.price
          : minProductLimit,
      car: {
        model: data.makeId
          ? { label: data?.model, value: data?.modelId }
          : { label: '', value: '' },
        power:
          data.makeId && data.modelId && data.power
            ? {
              label: data?.power?.toString(),
              value: data?.power?.toString(),
            }
            : { value: '', label: '' },
        brand: { label: data?.make, value: data?.makeId },
        manufactureYear:
          data.makeId && data.modelId
            ? {
              label: data?.year?.toString(),
              value: data?.year?.toString(),
            }
            : { label: '', value: '' },
      },
    });

    setWantFindCar(false);
    setIsCarNumberSmartComponentShowing(false);
  }, [wantFindCar, data]);

  const handleManualInput = useHandleManualInput(carNumber);
  const { reset } = useQueryErrorResetBoundary();

  useEffect(() => {
    if (wantFindCar && error) {
      handleManualInput();
      setHasUnknownCarNumberError(true);
      reset();
      setWantFindCar(false);
    }
  }, [error]);

  return refetch;
};
