import { useEffect } from 'react';

import { ErrorCode, Endpoints, QUERY_KEYS } from '@shared/constants';
import { useRequest, useStores } from '@shared/hooks';

import {
  AuthBoxAnalyticEvent,
  type AuthenticationResponseType,
  type OnAnalyticEventSend,
} from '../auth-box.type';
import { hasError } from '@shared/utils';

export const useHandleConfirmAuthentication = (
  isLoadingAuthentication?: boolean,
  resAuthentication?: AuthenticationResponseType,
  onAnalyticEventSend?: OnAnalyticEventSend
) => {
  const {
    MainStore: {
      errorStore: { setErrorRetry },
      authStore: {
        callOtp,
        authenticationToken,
        profile,
        confirmationId,
        setDisableForm,
        setOtpIsLoading,
        setOtpErrorCode,
        handleOtpResponse,
      },
    },
  } = useStores();

  const requestData = {
    code: callOtp,
    confirmationId: resAuthentication?.confirmationId,
  };

  const { isLoading, error, res, refetch, remove } = useRequest(
    QUERY_KEYS.confirmAuth,
    'post',
    Endpoints.CONFIRMAUTH,
    requestData,
    [callOtp, resAuthentication?.authenticationToken],
    true,
    authenticationToken
  );

  useEffect(() => {
    const shouldRefetchOtp =
      callOtp &&
      !profile &&
      confirmationId &&
      resAuthentication &&
      authenticationToken &&
      !isLoadingAuthentication;

    if (shouldRefetchOtp) {
      setDisableForm(true);
      refetch();
    }
  }, [
    profile,
    callOtp,
    confirmationId,
    resAuthentication,
    authenticationToken,
    isLoadingAuthentication,
  ]);

  useEffect(() => {
    setOtpIsLoading(isLoading);

    if (!isLoading && res && res.accessToken) {
      handleOtpResponse(res);
      setOtpErrorCode(false);
      setErrorRetry(false);
      onAnalyticEventSend &&
        onAnalyticEventSend(AuthBoxAnalyticEvent.ON_ENTER_CODE_SUCCESS);
    }

    const isUnknownUserOrCode =
      !isLoading && hasError(error, ErrorCode.UNKNOWN_USER_OR_CODE);

    if (isUnknownUserOrCode) {
      setOtpErrorCode(true);
    }
  }, [isLoading, error, res]);

  return {
    isLoadingConfirmAuthentication: isLoading,
    errorConfirmAuthentication: error,
    resConfirmAuthentication: res,
    refetchConfirmAuthentication: refetch,
    removeConfirmAuthentication: remove,
  };
};
