import { PolicyholderForm } from '@entities/import-smart-components/policyholder-form';
import { FallbackSkeleton } from '@shared/components';
import { FiasLevel } from '@shared/constants';
import {
  useBlockNextStep,
  useHandleErrorRequest,
  useIsDesktop,
  useStores,
} from '@shared/hooks';
import {
  getUpdatedProfile,
  useHandleUpdateProfile,
} from '@shared/queries/update-profile';
import { observer } from 'mobx-react-lite';
import {
  Suspense,
  forwardRef,
  useEffect,
  useMemo,
  useState,
  type Ref,
} from 'react';

import type {
  SmartComponentAdapterProps,
  PolicyholderFormValues,
  PolicyholderFormDisabledFields,
  PolicyholderFormOptions,
} from '@smart-components/index';

export const PolicyholderFormAdapter = observer(
  forwardRef(
    (
      {
        value,
        onChange,
        isSubmitting,
        fieldState,
      }: SmartComponentAdapterProps<PolicyholderFormValues>,
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const isDesktop = useIsDesktop();
      const {
        MainStore: {
          authStore: { disableForm, profile },
          productStore: {
            formState: { WhoIsPolicyholder, CarOwner },
            agentLogin,
          },
          applicationStore: {
            updateFormValue,
            isPolicyholderTheOwner,
            isRegistration,
            wantNextStep,
          },
        },
      } = useStores();

      const [disabledFields, setDisabledFields] =
        useState<PolicyholderFormDisabledFields>(() => ({
          birthday: false,
          middlename: false,
          name: false,
          surname: false,
          passport: false,
          passportDate: false,
          passportByWho: false,
          passportCode: false,
          address: false,
          phone: false,
          email: false,
        }));
      const [formValue, setFormValue] = useState(() => ({ ...value }));

      const hasAuthorized = WhoIsPolicyholder?.hasAuthorized;
      const updatedProfile = getUpdatedProfile(value, profile?.profile);

      const { status, refetch, error, isSuccess } = useHandleUpdateProfile({
        data: updatedProfile,
        isNeedUpdate: wantNextStep,
      });
      useHandleErrorRequest({ status, refetch, error });

      const isUnblock = updatedProfile ? updatedProfile && isSuccess : true;
      useBlockNextStep(isUnblock);

      const fiasLevel = WhoIsPolicyholder?.address?.fias_level;

      const isAvailableFiasLevel =
        fiasLevel === FiasLevel.HOUSE || fiasLevel === FiasLevel.APARTMENT;

      const whoIsPolicyholderDeps = [
        WhoIsPolicyholder?.birthday?.toString(),
        WhoIsPolicyholder?.middlename,
        WhoIsPolicyholder?.name,
        WhoIsPolicyholder?.surname,
        WhoIsPolicyholder?.passport,
        WhoIsPolicyholder?.passportDate?.toString(),
        WhoIsPolicyholder?.passportByWho,
        WhoIsPolicyholder?.passportCode,
        WhoIsPolicyholder?.address?.value,
        WhoIsPolicyholder?.address?.fias_id,
        WhoIsPolicyholder?.address?.fias_level,
        WhoIsPolicyholder?.phone,
        WhoIsPolicyholder?.email,
      ];

      useEffect(() => {
        const isValueEmpty = !Object.keys(value || {}).filter(
          (key) => key !== 'isValid'
        )?.length;

        const isFiasLevelChanged =
          !!WhoIsPolicyholder?.address?.fias_level &&
          !value?.address?.fias_level;

        if (hasAuthorized && (isValueEmpty || isFiasLevelChanged)) {
          setFormValue({
            ...value,
            birthday: WhoIsPolicyholder?.birthday,
            middlename: WhoIsPolicyholder?.middlename,
            name: WhoIsPolicyholder?.name,
            surname: WhoIsPolicyholder?.surname,
            passport: WhoIsPolicyholder?.passport,
            passportDate: WhoIsPolicyholder?.passportDate,
            passportByWho: WhoIsPolicyholder?.passportByWho,
            passportCode: WhoIsPolicyholder?.passportCode,
            address: isAvailableFiasLevel
              ? WhoIsPolicyholder?.address
              : undefined,
            phone: WhoIsPolicyholder?.phone,
            email: WhoIsPolicyholder?.email,
            isValid: WhoIsPolicyholder?.isValid,
          });
        }
      }, [hasAuthorized, isAvailableFiasLevel, ...whoIsPolicyholderDeps]);

      useEffect(() => {
        if (hasAuthorized) {
          setDisabledFields({
            birthday: !!WhoIsPolicyholder?.birthday,
            middlename: !!WhoIsPolicyholder?.middlename,
            name: !!WhoIsPolicyholder?.name,
            surname: !!WhoIsPolicyholder?.surname,
            passport: !!WhoIsPolicyholder?.passport,
            passportDate: !!WhoIsPolicyholder?.passportDate,
            passportByWho: !!WhoIsPolicyholder?.passportByWho,
            passportCode: !!WhoIsPolicyholder?.passportCode,
            address: isAvailableFiasLevel,
            phone: !!WhoIsPolicyholder?.phone,
            email: false,
          });
        }
      }, [hasAuthorized, isAvailableFiasLevel, ...whoIsPolicyholderDeps]);

      useEffect(() => {
        const hasAddress = WhoIsPolicyholder?.address?.fias_id;
        if (hasAuthorized && hasAddress) {
          setFormValue((prev) => ({
            ...prev,
            address: {
              value: WhoIsPolicyholder?.address?.value,
              fias_id: WhoIsPolicyholder?.address?.fias_id,
              fias_level: WhoIsPolicyholder?.address?.fias_level,
            },
          }));

          setDisabledFields((prev) => ({
            ...prev,
            address: true,
          }));
        }
      }, [
        hasAuthorized,
        WhoIsPolicyholder?.address?.value,
        WhoIsPolicyholder?.address?.fias_id,
        WhoIsPolicyholder?.address?.fias_level,
      ]);

      useEffect(() => {
        updateFormValue('PolicyholderForm', formValue);
      }, [formValue]);

      useEffect(() => {
        if (isPolicyholderTheOwner) {
          // Изменение формы CarOwner если выбрано "Страхователь является владельцем"
          updateFormValue('CarOwner', {
            ...CarOwner,
            birthday: value?.birthday,
            middlename: value?.middlename,
            name: value?.name,
            surname: value?.surname,
            passport: value?.passport,
            passportDate: value?.passportDate,
            passportByWho: value?.passportByWho,
            passportCode: value?.passportCode,
            address: value?.address,
          });
        }
      }, [
        value?.birthday?.toString(),
        value?.middlename,
        value?.name,
        value?.surname,
        value?.passport,
        value?.passportDate?.toString(),
        value?.passportByWho,
        value?.passportCode,
        value?.address?.value,
        value?.address?.fias_id,
        value?.address?.fias_level,
      ]);

      useEffect(() => {
        if (isRegistration) {
          updateFormValue('PolicyholderForm', { ...value, isValid: true });
        }
      }, [isRegistration]);

      const options: PolicyholderFormOptions = useMemo(
        () => ({ agentLogin, disabledFields }),
        [agentLogin, disabledFields]
      );

      if (!hasAuthorized || isRegistration) {
        return null;
      }
      return (
        <Suspense
          fallback={<FallbackSkeleton height={isDesktop ? 640 : 920} />}
        >
          <PolicyholderForm
            ref={forwardRef}
            value={value}
            onChange={onChange}
            isSubmitting={isSubmitting}
            options={options}
            disabled={disableForm}
            fieldState={fieldState}
          />
        </Suspense>
      );
    }
  )
);

PolicyholderFormAdapter.displayName = 'PolicyholderFormAdapter';
