import { Endpoints } from '@shared/constants';
import { fetchData } from '@shared/utils';
import { useQuery } from 'react-query';
import type { UpdateProfileRequest } from './types';
import type { UpdateProfileResponse } from '@shared/types';
import { profile } from './schema';
import type { AxiosError } from 'axios';

interface Props {
  data?: UpdateProfileRequest;
  isNeedUpdate: boolean;
}

export const useHandleUpdateProfile = ({ data, isNeedUpdate }: Props) => {
  const enabled = profile.isValidSync(data) && isNeedUpdate;

  return useQuery<UpdateProfileResponse, AxiosError<{ code: string }>>(
    ['update-profile', data],
    fetchData<UpdateProfileResponse>({
      url: Endpoints.UPDATE_PROFILE,
      method: 'POST',
      data,
    }),
    { enabled }
  );
};
