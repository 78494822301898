export const PlatformType = 'WEB';

export enum Endpoints {
  INIT_ORDER = '/v1/payment/init-order',
  REFRESH = '/v1/user/refresh-token',
  AUTH = '/v1/user/authorization',
  REG = '/v1/user/registration',
  AUTHENTICATION = '/v1/user/authentication',
  GET_PROFILE = '/v1/user/get-profile',
  UPDATE_PROFILE = '/v1/user/update-profile',
  COMPLETE_PAYMENT = '/v1/payment/complete-payment',
  GET_INSURANCE_PRODUCT = '/v1/references/get-insurance-product',
  CONFIRMAUTH = '/v3/user/confirm-authentication',
  GET_PRICES = '/v3/subscription/kasko/get-prices',
  SUBMIT_ORDER = '/v3/subscription/submit/kasko',
  INSURANSE_LIMIT_START = '/v3/references/insurance-limit-start',
  GET_AGE_RATION = '/v3/references/get-age-ratio',
  GET_RISKS = '/v4/references/RISK',
  TERRITORY = '/v4/references/TERRITORY',
  GET_SPORT_ACTIVITY = '/v4/references/ACTIVITY',
  GET_CAR = '/v1/vehicle/externalInfo',
  GET_VEHICLE_REGION = '/v4/references/VEHICLE_REGION',
  GET_VEHICLE_REPAIR_TYPE = '/v4/references/VEHICLE_REPAIR_TYPE',
  GET_INSURANCE_PRODUCT_PERIOD = '/v4/references/INSURANCE_PRODUCT_PERIOD',
  GET_FRANCHISE = '/v4/references/FRANCHISE',
  GET_REGION = '/v1/vehicle/region',
  GET_BRANDS = '/v1/vehicle/makes',
  DADATA = '/v3/dadata',
  PAYMENT_STAGE = '/v1/payment-stage',
}
