import { B2P } from '@entities/import-smart-components/b2p';
import { type Ref, Suspense, forwardRef } from 'react';
import { FallbackSkeleton } from '@shared/components';
import type {
  SmartComponentAdapterProps,
  B2PValues,
} from '@smart-components/index';
import { observer } from 'mobx-react-lite';
import { useStores } from '@shared/hooks';
// TODO: доработать, когда будет готова аналитика

export const B2PAdapter = observer(
  forwardRef(
    (
      {
        value,
        onChange,
        isSubmitting,
        fieldState,
      }: SmartComponentAdapterProps<B2PValues>,
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const {
        MainStore: {
          authStore: { disableForm },
        },
      } = useStores();

      return (
        <Suspense fallback={<FallbackSkeleton height={0} transparent />}>
          <B2P
            ref={forwardRef}
            value={value}
            onChange={onChange}
            isSubmitting={isSubmitting}
            fieldState={fieldState}
            disabled={disableForm}
            options={null}
          />
        </Suspense>
      );
    }
  )
);

B2PAdapter.displayName = 'B2PAdapter';
