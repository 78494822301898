import { DocumentPolicy, MessagePolicy, Ruble } from '@pulse-web-ui/icons';
import { useScrollToTop, useStores } from '@shared/hooks';
import { getFormattedDate, isAppleSafari } from '@shared/utils';
import { lazy, Suspense, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import type { TFunction } from 'i18next';

import { formatSelectedDate } from './utils';

// TODO: доработать, когда будет готова веб-аналитика

const SuccessfulPaymentComponent = lazy(() =>
  import('@pulse-smart-components-kit/payment/lib/successful-payment').then(
    (module) => ({
      default: module.SuccessfulPayment,
    })
  )
);

const getCards = (t: TFunction) => [
  {
    id: 1,
    icon: <Ruble width={64} />,
    description: t('COMMON:pay.description-1'),
  },
  {
    id: 2,
    icon: <MessagePolicy width={64} />,
    description: t('COMMON:pay.description-2'),
  },
  {
    id: 3,
    icon: <DocumentPolicy width={64} />,
    description: t('COMMON:pay.description-3'),
  },
];

export const SuccessPayment = () => {
  const {
    MainStore: {
      productStore: {
        formState: { PolicyholderForm, InsuranceParameters },
      },
      authStore: { setDisableForm },
      initProductStore: { initFormState },
      applicationStore: {
        setActiveStep,
        setIsPaymentStep,
        setIsResetFormState,
      },
      productStore: { setFormState },
    },
  } = useStores();

  const { t } = useTranslation();

  const cards = getCards(t);

  const queryClient = useQueryClient();

  const { email, startDate } = useMemo(
    () => ({
      email: PolicyholderForm?.email ?? '',
      startDate: formatSelectedDate(
        getFormattedDate(InsuranceParameters?.period?.startDate ?? new Date())
      ),
    }),
    [PolicyholderForm?.email, InsuranceParameters?.period?.startDate]
  );

  const handleMobileAppClickForBrowser = () => {
    const deepLink = window.envUrls.INSURANCE_MOBILE_APP_HOT_LINK;

    isAppleSafari()
      ? (window.location.href = deepLink)
      : window.open(deepLink, '_blank');
  };

  useScrollToTop();

  useEffect(() => {
    localStorage.removeItem('referenceSuccess');
    localStorage.removeItem('operationId');
    localStorage.removeItem('paymentSuccess');
    setDisableForm(false);

    setActiveStep(1);

    setIsPaymentStep(false);
    setFormState({
      ...initFormState,
    });
    setIsResetFormState(true);
    queryClient.removeQueries(); // сброс кэша всех запросов для правильного перезапуска приложения
  }, []);

  return (
    <Suspense fallback="">
      <SuccessfulPaymentComponent
        onClick={handleMobileAppClickForBrowser}
        email={email}
        date={startDate}
        cards={cards}
      />
    </Suspense>
  );
};
