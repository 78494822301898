import { QueryStatus } from 'react-query';
import { useStores } from './use-stores';
import { useEffect } from 'react';
import { type AxiosError, isAxiosError } from 'axios';

interface Props {
  status: QueryStatus;
  refetch: VoidFn;
  error: AxiosError<{ code: string }> | null;
}

export const useHandleErrorRequest = ({ status, refetch, error }: Props) => {
  const {
    MainStore: {
      errorStore: { setHasError, setRefetch },
    },
  } = useStores();

  useEffect(() => {
    if (isAxiosError(error)) {
      const status = error.response?.status;
      const code = error.response?.data?.code;

      if (
        (status === 400 && code === 'TECHNICAL_ERROR') ||
        Number(status) >= 500
      ) {
        setHasError(true);
        setRefetch(refetch);
      }
    } else if (status === 'success') {
      setHasError(false);
      setRefetch(undefined);
    }
  }, [error, refetch, status]);
};
