import type { InsuranceProduct } from '@shared/types';
import { Endpoints, PRODUCT_CODE } from '@shared/constants';
import { useQuery } from 'react-query';
import { fetchData } from '@shared/utils';
import { useStores } from '@shared/hooks';
import { useEffect } from 'react';
import type { AxiosError } from 'axios';

export const useHandleProduct = () => {
  const {
    MainStore: {
      initProductStore: { initState, setInitState },
    },
  } = useStores();

  const query = useQuery<InsuranceProduct, AxiosError<{ code: string }>>(
    ['get-insurance-product'],
    fetchData<InsuranceProduct>({
      url: Endpoints.GET_INSURANCE_PRODUCT,
      method: 'POST',
      data: { productCode: PRODUCT_CODE },
    }),
    { cacheTime: Infinity, staleTime: Infinity }
  );

  const { isLoading, data } = query;

  useEffect(() => {
    if (!isLoading && data) {
      setInitState({ ...initState, ...data });
    }
  }, [isLoading, data]);

  return query;
};
