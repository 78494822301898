import { useEffect } from 'react';

import { useStores } from './use-stores';

export const useBlockNextStep = (isUnblock: boolean) => {
  const {
    MainStore: {
      applicationStore: { setIsBlockNextStep, isBlockNextStep },
    },
  } = useStores();

  useEffect(() => {
    setIsBlockNextStep(true);
  }, []);

  useEffect(() => {
    if (isBlockNextStep && isUnblock) {
      setIsBlockNextStep(false);
    }
  }, [isUnblock, isBlockNextStep]);
};
