/* eslint-disable indent */
import { Suspense, forwardRef, useEffect, type Ref } from 'react';
import { observer } from 'mobx-react-lite';

import type { SmartComponentAdapterProps } from '@smart-components/index';
import { CheckPolicy } from '@entities/import-smart-components/check-policy';
import type { CheckPolicyValues } from '@pulse-smart-components-kit/check-policy';

import { Loader } from './components';
import { schema } from './schema';
import { useStores, useHandleErrorRequest } from '@shared/hooks';

import { useGetRequestData } from '../submit-adapter/hooks';
import { useHandleSubmit } from '../submit-adapter/queries';
import {
  getCompletePaymentData,
  useCompletePayment,
  useInitOrder,
} from '@shared/queries';
import { useGetOptions } from './hooks';
import { useNavigate } from 'react-router-dom';
import { PublicRoutes } from '@shared/routes';

export const CheckPolicyAdapter = observer(
  forwardRef(
    (
      {
        value,
        onChange,
        isSubmitting,
        fieldState,
      }: SmartComponentAdapterProps<CheckPolicyValues>,
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const {
        MainStore: {
          applicationStore: {
            isPaymentStep,
            setDisabledSubmit,
            updateFormValue,
            activeStep,
            retryPayment,
            flowConfig: { maxSteps },
          },
          productStore: { setIsPolicyBanned, setOrderData, agentLogin },
          authStore: { disableForm },
        },
      } = useStores();
      const navigate = useNavigate();

      const requestData = useGetRequestData();
      const {
        data: submitData,
        isLoading: submitIsLoading,
        status: submitStatus,
        refetch: refetchSubmit,
        error: submitError,
      } = useHandleSubmit(requestData);

      useHandleErrorRequest({
        status: submitStatus,
        refetch: refetchSubmit,
        error: submitError,
      });

      const { resInit } = useInitOrder(updateFormValue, submitData);

      useCompletePayment(getCompletePaymentData(submitData, resInit));

      useEffect(() => {
        if (
          submitError &&
          submitError.response?.status === 400 &&
          submitError.response?.data?.code === 'CALCULATION_ERROR'
        ) {
          setIsPolicyBanned(true);
          navigate(PublicRoutes.POLICY_BANNED);
        }
      }, [submitError]);

      useEffect(() => {
        if (activeStep === maxSteps && !retryPayment && !agentLogin)
          refetchSubmit();
      }, [activeStep, maxSteps, retryPayment, agentLogin]);

      useEffect(() => {
        if (submitData) {
          setOrderData(submitData);
        }
      }, [submitData]);

      useEffect(() => {
        setDisabledSubmit(submitIsLoading);

        return () => {
          setDisabledSubmit(false);
        };
      }, [submitIsLoading]);

      // TODO: Доработать, когда будет готова аналитика
      const handleAnalyticEventSend = () => {};
      const isAcceptCheckboxDisabled = disableForm || isPaymentStep;

      const options = useGetOptions(requestData);

      if (submitIsLoading) {
        return <Loader />;
      }

      return (
        <Suspense>
          <CheckPolicy
            ref={forwardRef}
            value={value}
            onChange={onChange}
            isSubmitting={isSubmitting}
            options={options}
            disabled={isAcceptCheckboxDisabled}
            fieldState={fieldState}
            formSchema={schema}
            onAnalyticEventSend={handleAnalyticEventSend}
          />
        </Suspense>
      );
    }
  )
);
