import { ErrorCode, Endpoints } from '@shared/constants';
import { fetchData, hasError } from '@shared/utils';
import { useQuery } from 'react-query';
import { requestBody } from '../../schemas';
import { useEffect } from 'react';
import { useStores } from '@shared/hooks';
import { useNavigate } from 'react-router-dom';
import { PublicRoutes } from '@shared/routes';
import type { ResponseError } from '@shared/types';
import type { SubmitRequest, SubmitResponse } from '../../types';
import type { AxiosError } from 'axios';

export const useHandleSubmit = (data: SubmitRequest) => {
  const navigate = useNavigate();
  const {
    MainStore: {
      applicationStore: {
        setIsPaymentStep,
        retryPayment,
        setRetryPayment,
        updateFormValue,
      },
      initProductStore: { setInitOrder },
      errorStore: { setErrorRetry },
      authStore: { authTokens, accessToken, setDisableForm },
      productStore: { formState, setOrderData, setIsPolicyBanned },
    },
  } = useStores();

  const enabled = requestBody.isValidSync(data);

  const {
    data: submitData,
    isLoading,
    status,
    refetch,
    error,
  } = useQuery<SubmitResponse, AxiosError<{ code: string }>>(
    ['submit', data],
    fetchData<SubmitResponse>({
      url: Endpoints.SUBMIT_ORDER,
      method: 'POST',
      data,
    }),
    { enabled }
  );

  useEffect(() => {
    setDisableForm(isLoading);
    if (isLoading && !retryPayment) {
      updateFormValue('B2P', {
        initOrder: undefined,
        showInitOrder: false,
        isValid: true,
      });
      setInitOrder(undefined);
      setIsPaymentStep(false);
      setErrorRetry(false);
    }
  }, [isLoading]);

  useEffect(() => {
    if (retryPayment) {
      refetch();
    }
  }, [retryPayment]);

  useEffect(() => {
    if (!isLoading && submitData && accessToken) {
      setDisableForm(false);
      setOrderData(submitData);
      setErrorRetry(false);
      setRetryPayment(false);
      updateFormValue('CheckPolicy', {
        documentsList: submitData.clientDocument,
        token: accessToken,
        acceptRequirements: formState?.CheckPolicy?.acceptRequirements,
        isValid: formState?.CheckPolicy?.isValid,
      });
    }

    if (!isLoading && error) {
      if (hasError(error as ResponseError, ErrorCode.USER_DATA_ERROR)) {
        setIsPolicyBanned(true);
        navigate(PublicRoutes.POLICY_BANNED);
      }
      setDisableForm(false);
    }
  }, [isLoading, submitData, error, authTokens]);

  return {
    data: submitData,
    isLoading,
    status,
    refetch,
    error,
  };
};
