import { useEffect } from 'react';

import { ErrorCode, Endpoints, QUERY_KEYS } from '@shared/constants';

import { useRequest, useStores } from '@shared/hooks';
import type { GetSubmitOrderResponse, GetSubmitOrderRequest } from './types';
import { getOrderData } from './utils';
import { hasError } from '@shared/utils';
import type { FormBuilderUpdateHandler } from '@features/form-builder';
import { sendAnalyticEvent, analyticEvents } from '@app/web-analytic';
import { useNavigate } from 'react-router-dom';
import { PublicRoutes } from '@shared/routes';

export const useSubmitOrder = (updateFormValue: FormBuilderUpdateHandler) => {
  const navigate = useNavigate();
  const {
    MainStore: {
      applicationStore: { setIsPaymentStep, retryPayment, setRetryPayment },
      initProductStore: { setInitOrder, initState },
      errorStore: { setErrorRetry },
      authStore: { authTokens, accessToken, setDisableForm },
      productStore: {
        formState,
        currency,
        setOrderData,
        price: { promoCode },
        setIsPolicyBanned,
      },
    },
  } = useStores();

  const requestData: GetSubmitOrderRequest = getOrderData({
    formState,
    initState,
    currency,
    promoCode,
  });

  const { isLoading, res, error, remove } = useRequest<GetSubmitOrderResponse>(
    QUERY_KEYS.submitOrder,
    'post',
    Endpoints.SUBMIT_ORDER,
    requestData,
    [],
    true,
    accessToken
  );

  useEffect(() => {
    setDisableForm(isLoading);
    if (isLoading && !retryPayment) {
      updateFormValue('B2P', {
        initOrder: undefined,
        showInitOrder: false,
        isValid: true,
      });
      setInitOrder(undefined);
      setIsPaymentStep(false);
      setErrorRetry(false);
    }
  }, [isLoading]);

  useEffect(() => {
    if (!isLoading && res && accessToken) {
      setDisableForm(false);
      setOrderData(res);
      setErrorRetry(false);
      setRetryPayment(false);
      updateFormValue('CheckPolicy', {
        documentsList: res.clientDocument,
        token: accessToken,
        acceptRequirements: formState?.CheckPolicy?.acceptRequirements,
        isValid: formState?.CheckPolicy?.isValid,
      });

      if (!error) sendAnalyticEvent(analyticEvents.travelToStepDocs);
    }

    if (!isLoading && error) {
      if (hasError(error, ErrorCode.USER_DATA_ERROR)) {
        setIsPolicyBanned(true);
        navigate(PublicRoutes.POLICY_BANNED);
      }
      setDisableForm(false);
    }
  }, [isLoading, res, error, authTokens]);

  // TODO: Вернуть refetch сабмита обратно
  return { isLoading, res, refetch: () => {}, error, remove };
};
