import { t } from 'i18next';

export const formatSelectedDate = (selectedDate: string) => {
  if (!selectedDate) {
    return '';
  }
  const date = new Date(selectedDate)
    .toLocaleDateString('ru-RU', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    })
    .replace('г.', t('COMMON:pay.ofYear'));

  return date;
};
