import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { hasError, phoneValueCasting } from '@shared/utils';
import { useRequest, useStores } from '@shared/hooks';
import { ErrorCode, Endpoints, QUERY_KEYS } from '@shared/constants';

import { AuthenticationResponseType } from '../auth-box.type';

export const useHandleAuthentication = (
  setFormValueError: (fieldName: string, error: any) => void
) => {
  const { t } = useTranslation();
  const {
    MainStore: {
      errorStore: { setErrorRetry },
      authStore: {
        callAuth,
        refetchAuthentication,
        authTokens,
        setRefetchAuthentication,
        setAuthIsLoading,
        setDisableForm,
        setAuthError,
        handleStoreAuthResponse,
      },
    },
  } = useStores();

  const requestData = {
    authenticationUser: { phoneNumber: phoneValueCasting(callAuth) },
  };

  const { isLoading, error, res, refetch } =
    useRequest<AuthenticationResponseType>(
      QUERY_KEYS.auth,
      'post',
      Endpoints.AUTHENTICATION,
      requestData,
      [callAuth],
      true
    );

  useEffect(() => {
    const shouldRefetch =
      callAuth && !authTokens && (!res || refetchAuthentication);

    if (shouldRefetch) {
      refetch();

      if (refetchAuthentication) setRefetchAuthentication(false);
    }
  }, [callAuth, res, authTokens, refetchAuthentication]);

  useEffect(() => {
    setAuthIsLoading(isLoading);
    if (!isLoading) {
      if (res) {
        setErrorRetry(false);
        handleStoreAuthResponse(res);
      }
      if (hasError(error, ErrorCode.RATE_LIMIT_EXCEEDED)) {
        setDisableForm(false);
        setAuthError(true);
        setFormValueError(
          'WhoIsPolicyholder.phone',
          t('COMMON:errors.authLimitExceeded')
        );
      }
    }
  }, [isLoading, error, res]);

  return {
    isLoadingAuthentication: isLoading,
    errorAuthentication: error,
    resAuthentication: res,
    refetchAuthentication: refetch,
  };
};
