import { Endpoints, QUERY_KEYS } from '@shared/constants';
import { useRequest, useStores } from '@shared/hooks';
import { getFormattedDate } from '@shared/utils';
import { useEffect, useMemo } from 'react';

import type { UpdateProfileRequest } from './use-update-profile.types';
import type { UpdateProfileResponse } from '@shared/types';

// TODO: удалить

export const useUpdateProfile = () => {
  const {
    MainStore: {
      errorStore: { setErrorRetry },
      authStore: {
        handleProfile,
        profile,
        accessToken,
        setDisableForm,
        handleUpdateDisableProfileState,
      },
      productStore: { formState },
    },
  } = useStores();

  const policyholderForm = formState?.PolicyholderForm;

  const requestData: UpdateProfileRequest = useMemo(() => {
    const changedBirthDate = policyholderForm?.birthday
      ? getFormattedDate(policyholderForm?.birthday)
      : '';
    const email =
      profile?.profile?.email !== policyholderForm?.email
        ? policyholderForm?.email
        : undefined;
    const birthDate =
      profile?.profile?.birthDate !== changedBirthDate
        ? changedBirthDate
        : undefined;
    const firstName =
      profile?.profile?.firstName !== policyholderForm?.name
        ? policyholderForm?.name
        : undefined;
    const middleName =
      policyholderForm?.middlename &&
      profile?.profile?.middleName !== policyholderForm?.middlename
        ? policyholderForm?.middlename
        : undefined;
    const lastName =
      profile?.profile?.lastName !== policyholderForm?.surname
        ? policyholderForm?.surname
        : undefined;

    return {
      clientChange: {
        email,
        birthDate,
        firstName,
        middleName,
        lastName,
      },
    };
  }, [
    policyholderForm?.birthday,
    policyholderForm?.name,
    policyholderForm?.middlename,
    policyholderForm?.surname,
    policyholderForm?.email,
    profile?.profile?.email,
    profile?.profile?.birthDate,
    profile?.profile?.firstName,
    profile?.profile?.middleName,
    profile?.profile?.lastName,
  ]);

  const { isLoading, res, refetch } = useRequest<UpdateProfileResponse>(
    QUERY_KEYS.updateProfile,
    'post',
    Endpoints.UPDATE_PROFILE,
    requestData,
    [profile, formState],
    true,
    accessToken
  );

  useEffect(() => {
    if (!isLoading && res) {
      const profileValues = {
        name: !!res?.profile?.firstName,
        surname: !!res?.profile?.lastName,
        secondName: res?.lockedFields?.middleName || !!res?.profile?.middleName,
        birthDate: !!res?.profile?.birthDate,
      };

      setDisableForm(false);
      setErrorRetry(false);
      handleProfile(res);
      handleUpdateDisableProfileState(profileValues);
    }
  }, [isLoading, res]);

  return refetch;
};
