import { CarNumber } from '@entities/import-smart-components/car-number';
import { FallbackSkeleton } from '@shared/components';
import { useHandleManualInput, useStores } from '@shared/hooks';
import { observer } from 'mobx-react-lite';
import { Suspense, forwardRef, useCallback, useMemo } from 'react';

import type { CarNumberValues } from '@smart-components/car-number/types';
import type { SmartComponentAdapterProps } from '@smart-components/index';
import type { Ref } from 'react';

export const CarNumberAdapter = observer(
  forwardRef(
    (
      { value, onChange }: SmartComponentAdapterProps<CarNumberValues>,
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const {
        MainStore: {
          applicationStore: {
            setWantFindCar,
            isCarNumberSmartComponentShowing,
            isCarNumberNotReceived,
            setIsCarNumberNotReceived,
          },
        },
      } = useStores();

      const carNumber = value?.carNumber;

      const handleHide = useCallback(() => {
        setWantFindCar(true);
      }, []);

      const handleManualInput = useHandleManualInput(carNumber);

      const options = useMemo(
        () => ({
          onNextStep: handleHide,
          onManualInput: handleManualInput,
          isCarNumberNotReceived,
          setIsCarNumberNotReceived,
        }),
        [
          handleHide,
          handleManualInput,
          isCarNumberNotReceived,
          setIsCarNumberNotReceived,
        ]
      );

      if (!isCarNumberSmartComponentShowing) return;

      return (
        <Suspense fallback={<FallbackSkeleton height={274} />}>
          <CarNumber
            value={value}
            ref={forwardRef}
            onChange={onChange}
            isSubmitting={false}
            options={options}
          />
        </Suspense>
      );
    }
  )
);

CarNumberAdapter.displayName = 'CarNumberAdapter';
